'use client';
import { ChevronLeftIcon, SearchIcon } from '@c/icons';
import { ChevronDown } from '@c/icons/chevron';
import { ChangeEvent, KeyboardEventHandler, useRef } from 'react';

import { cva, VariantProps } from 'class-variance-authority';
import Button from '@ui/Button';

export const InputStyles = cva(
  'flex flex-row items-center justify-between grow rounded-lg gap-[0.8rem] relative',
  {
    variants: {
      type: {
        search: 'px-[1.6rem] py-[1.2rem] max-h-[4.4rem]', // maybe these should be width props instead of based on type ?
        tel: 'px-[2.4rem] py-[1rem]', // maybe these should be width props instead of based on type ?
        email: 'px-[2.4rem] py-[1rem]', // maybe these should be width props instead of based on type ?
        password: 'px-[2.4rem] py-[1rem]',
        text: 'px-[2.4rem] py-[1rem]',
        number: 'px-[2.4rem] py-[1rem]',
        date: 'px-[2.4rem] py-[1rem]',
        time: 'px-[2.4rem] py-[1rem]',
        noPadding: '',
      },
      stripe: { true: '!px-[1rem] py-[.9rem] text-[#30313e] text[14.88px]' },
      onDark: {
        true: 'bg-brand-white focus-within:bg-brand-white',
        false: 'bg-brand-darker-white  ',
      },
      full: {
        true: 'w-full max-w-full',
      },
      focus: {
        default:
          'focus-within:outline focus-within:outline-2 focus-within:outline-black focus-within:bg-transparent',
      },
      error: {
        true: 'outline outline-2 outline-brand-red focus-within:outline-brand-red',
      },
      disabled: {
        true: 'bg-brand-gray-light text-brand-gray select-none focus-within:outline-none cursor-not-allowed ',
      },
      readonly: {
        true: 'bg-brand-gray-light text-brand-gray select-none focus-within:outline-none cursor-not-allowed ',
      },
      bordered: {
        true: 'border-[1px] border-color-[#8e8e8e]',
      },
      noPadding: {
        true: '!px-0 !py-0',
      },
    },
    defaultVariants: {
      type: 'text',
      onDark: false,
    },
  }
);

interface Props extends VariantProps<typeof InputStyles> {
  placeholder: string;
  hideSearchIcon?: boolean;
  onClose?: () => void;
  onDark?: boolean;
  type:
    | 'tel'
    | 'search'
    | 'email'
    | 'text'
    | 'date'
    | 'number'
    | 'password'
    | 'time';
  onBlur?: () => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onClick?: () => void;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  center?: boolean;
  initialValue?: string;
  value?: string;
  trailingIconButton?: React.ReactNode;
  disabled?: boolean;
  bordered?: boolean;
  goBack?: () => void;
  searchIconRight?: boolean;
  showClearIcon?: boolean;
  stripe?: boolean;
  autoComplete?: string;
  noPadding?: boolean;
  maxLength?: number;
  id?: string;
  autoFocus?: boolean;
  className?: string;
  iconClassName?: string;
  step?: number;
}

const Input = ({
  placeholder,
  type,
  onDark,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  focus,
  error,
  center,
  full,
  initialValue,
  value,
  trailingIconButton,
  disabled,
  bordered,
  goBack,
  searchIconRight,
  readonly,
  stripe,
  autoComplete,
  noPadding,
  maxLength,
  id,
  autoFocus,
  className,
  iconClassName,
  hideSearchIcon,
  step,
}: Props) => {
  const ref = useRef<HTMLInputElement>(null);

  const AreaCodeSelect = () => (
    <div className="mr-[1.6rem] flex flex-row items-center gap-[0.8rem]">
      <span className="text-input font-semibold text-brand-white">+1</span>
      <ChevronDown />
    </div>
  );

  return (
    <div
      className={InputStyles({
        type,
        onDark,
        focus,
        error,
        full,
        disabled,
        bordered,
        stripe,
        noPadding,
        className,
      })}
    >
      {goBack && (
        <Button
          leadingIcon={<ChevronLeftIcon />}
          type="text"
          width="small"
          noXPadding
          onClick={goBack}
        />
      )}

      {type === 'search' && !searchIconRight && !hideSearchIcon && (
        <SearchIcon className={iconClassName} />
      )}

      {type === 'tel' && <AreaCodeSelect />}

      <input
        ref={ref}
        className={`w-full border-none border-transparent bg-transparent leading-input focus:outline-none focus:ring-0 sm:text-input ${
          center ? 'text-center' : ''
        } ${className}`}
        id={id ?? 'search-box-input'}
        value={value}
        type={type}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        defaultValue={initialValue}
        disabled={!!disabled}
        maxLength={maxLength}
        readOnly={!!readonly}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        step={step}
      />

      {trailingIconButton}
      {type === 'search' && searchIconRight && !hideSearchIcon && (
        <>
          <SearchIcon className={iconClassName} />
        </>
      )}
    </div>
  );
};

export default Input;
