import { useQuery, useQueryClient } from '@tanstack/react-query';
import { logEvent } from '@util/analytics';
import { analyticsPromise, AUTH, realtimeDatabase } from '@util/firebase';
import {
  addToInteractions,
  getUserById,
  updateUserPlatforms,
} from '@util/firestore/users';
import { useOnlineStatus } from '@util/hooks/useOnlineStatus';
import { getHostUrl, isMobile } from '@util/index';
import { logError } from '@util/logError';
import { setUserId } from 'firebase/analytics';
import { FirebaseError } from 'firebase/app';
import {
  ActionCodeSettings,
  applyActionCode,
  AuthErrorCodes,
  browserLocalPersistence,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  FacebookAuthProvider,
  fetchSignInMethodsForEmail,
  GoogleAuthProvider,
  linkWithCredential,
  OAuthProvider,
  onIdTokenChanged,
  PhoneAuthProvider,
  reauthenticateWithCredential,
  RecaptchaVerifier,
  sendEmailVerification as _sendEmailVerification,
  sendPasswordResetEmail as _sendPasswordResetEmail,
  setPersistence,
  signInWithCredential,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updateEmail,
  updatePassword,
  User,
  UserCredential,
} from 'firebase/auth';
import {
  get,
  onDisconnect,
  onValue,
  ref,
  serverTimestamp,
  update,
} from 'firebase/database';
import { default as Cookie, default as Cookies } from 'js-cookie';
import { UserDocument } from 'models/user';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

// import algoliaInsights from 'search-insights';

interface AuthProviderProps {
  children: ReactNode;
}

export interface Credentials {
  email: string;
  password: string;
}

export type FirebaseAuthFn<T extends Credentials | AuthProvider> = (
  args: T
) => Promise<FirebaseError | UserCredential>;

export const EMAIL_ERRORS = [
  AuthErrorCodes.USER_DELETED,
  AuthErrorCodes.EMAIL_EXISTS,
  AuthErrorCodes.INVALID_EMAIL,
  AuthErrorCodes.UNVERIFIED_EMAIL,
  AuthErrorCodes.INVALID_RECIPIENT_EMAIL,
];

export const PASSWORD_ERRORS = [
  AuthErrorCodes.WEAK_PASSWORD,
  AuthErrorCodes.INVALID_PASSWORD,
];

type AuthProvider = 'google' | 'facebook' | 'apple';

interface AuthContext {
  user: User | null | undefined;
  userDoc?: UserDocument | null;
  isLoadingUserDoc: boolean;
  isLoggingIn: boolean;
  logout: () => Promise<void>;
  loginEmailPassword: FirebaseAuthFn<Credentials>;
  loginSocial: FirebaseAuthFn<AuthProvider>;
  createAccountEmailPassword: FirebaseAuthFn<Credentials>;
  signInWithToken: FirebaseAuthFn<Credentials>;
  sendEmailVerification: () => Promise<void>;
  sendPasswordResetEmail: (email: string) => void;
  resetPassword: (oobCode: string, password: string) => Promise<void>;
  updateUserPassword: (oldPassword: string, password: string) => Promise<void>;
  isAccountEmailPassword: () => boolean;
  verifyEmail: (actionCode: string) => Promise<void>;
  fetchProvidersForEmail: (email: string) => Promise<string[]>;
  updateUserEmail: (email: string) => void;
  refreshUserDoc: (uid: string) => Promise<void>;
  sendPhoneVerification: (phoneNumber: string) => Promise<string>;
  signInWithPhone: (id: string, code: string) => Promise<UserCredential>;
  linkPhone: (id: string, code: string) => Promise<UserCredential>;
  showWelcomeBackModal: boolean;
  setShowWelcomeBackModal: (show: boolean) => void;
  observeProduct: (productId: string) => void;
}
const AuthContext = createContext({} as AuthContext);

const initAuthProvider = (p: AuthProvider) => {
  if (p === 'apple') return new OAuthProvider('apple.com');
  if (p === 'facebook') return new FacebookAuthProvider();
  return new GoogleAuthProvider();
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<User | null | undefined>();
  const [showWelcomeBackModal, setShowWelcomeBackModal] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const { setOffline } = useOnlineStatus();
  const queryClient = useQueryClient();

  // get user document
  const { data: userDoc, isLoading: isLoadingUserDoc } = useQuery({
    queryKey: ['authUser', user?.uid],
    queryFn: () => {
      if (!user?.uid) {
        return null;
      }
      return getUserById(user?.uid);
    },
  });

  useEffect(() => {
    fetch('/api/ip')
      .then((res) => {
        const ip =
          res.headers.get('x-client-ip')?.replaceAll('.', '_') || 'idk';
        function setOnlineStatus(uid: string) {
          const statusRef = ref(realtimeDatabase, `/status/${uid}`);
          const last_changed = serverTimestamp();
          update(statusRef, {
            state: 'online',
            web: last_changed,
            web_info: { ua: navigator.userAgent },
            last_changed,
            ['access_logs/' + ip + '/connected']: last_changed,
          }).catch((e) => {
            logError(e, 'set online status');
          });
          const infoConnectedRef = ref(realtimeDatabase, '.info/connected');
          onValue(infoConnectedRef, (snapshot) => {
            const isOnline = snapshot.val();
            if (isOnline) {
              const last_changed = serverTimestamp();
              onDisconnect(statusRef)
                .update({
                  state: 'offline',
                  web: last_changed,
                  last_changed,
                  ['access_logs/' + ip + '/disconnected']: last_changed,
                })
                .catch((e) => {
                  logError(e, 'set offline status');
                });
            }
          });
        }
        const onSuccess = async (uid?: string) => {
          if (user?.uid) {
            if (uid) {
              const statusRef = ref(realtimeDatabase, `/status/${user?.uid}`);
              // get data from ref
              const status = await get(statusRef);
              const last_changed = status.val()?.last_changed;
              const shouldShowWelcomeBackModal =
                last_changed < Date.now() - 1000 * 60 * 60 * 24 * 14;
              const isImpersonated = Cookies.get('impersonated');
              if (shouldShowWelcomeBackModal && !isImpersonated) {
                logEvent('welcome_back_modal_shown', {
                  uid: user?.uid,
                });
                setShowWelcomeBackModal(shouldShowWelcomeBackModal);
              }
              if (!isImpersonated) {
                setOnlineStatus(uid);
                updateUserPlatforms(uid);
                const analytics = await analyticsPromise;
                if (analytics) {
                  setUserId(analytics, uid);
                }
              }
            }
          }
        };
        onSuccess(user?.uid);
      })
      .catch((e) => {
        logError(e, 'fetch ip');
      });
  }, [user?.uid]);

  const sendPhoneVerification = async (phoneNumber: string) => {
    if (typeof window === 'undefined') return '';
    // set a global recaptchaVerifier if it doesn't exist so that we don't recreate it. User was getting this error if had a failed attempt and then tried to resubmit with a new phone number: https://stackoverflow.com/questions/54110489/recaptcha-has-already-been-rendered-in-this-element
    if (!(window as any).recaptchaVerifier) {
      (window as any).recaptchaVerifier = new RecaptchaVerifier(
        AUTH,
        'verify-phone-container',
        { size: 'invisible' }
      );
    }
    const provider = new PhoneAuthProvider(AUTH);
    const verificationId = await provider.verifyPhoneNumber(
      phoneNumber,
      (window as any).recaptchaVerifier
    );
    return verificationId;
  };

  const signInWithPhone = async (verificationId: string, code: string) => {
    const credential = PhoneAuthProvider.credential(verificationId, code);
    const userCredential = await signInWithCredential(AUTH, credential);
    setUser(userCredential.user);
    return userCredential;
  };

  const linkPhone = async (
    verificationId: string,
    verificationCode: string
  ) => {
    if (!AUTH.currentUser)
      throw new Error('An error occurred. Please try again.');
    try {
      const authCredential = PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );
      const cred = await linkWithCredential(AUTH.currentUser, authCredential);
      return cred;
    } catch (e) {
      const err = e as FirebaseError;
      switch (err.code) {
        case 'auth/invalid-verification-code':
          throw new Error('Invalid verification code');
        case 'auth/missing-verification-code':
          throw new Error('Missing verification code');
        case 'auth/code-expired':
          throw new Error('Verification code expired');
        case 'auth/credential-already-in-use':
          throw new Error('Phone number already in use');
        case 'auth/account-exists-with-different-credential':
          throw new Error('Phone number already in use');
        default:
          throw new Error('An unknown error occurred. Please try again.');
      }
    }
  };

  const sendEmailVerification = async () => {
    if (!AUTH.currentUser) throw new Error('No AUTH.currentUser');
    const actionCodeSettings: ActionCodeSettings = {
      url: `${getHostUrl()}/verify-email`,
    };
    await _sendEmailVerification(AUTH.currentUser, actionCodeSettings);
  };

  const verifyEmail = useCallback((actionCode: string) => {
    return applyActionCode(AUTH, actionCode);
  }, []);

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(AUTH, (user) => {
      // set user from email/password login
      setUser(user);
      if (user) {
        user.getIdToken().then((token) => {
          Cookie.set('auth', token, { expires: 1 });
        });
        Cookie.set('uid', user.uid);
        // remove klayvio script if user is logged in
        const klaviyoScript = document.getElementById('klaviyo-popup');
        if (klaviyoScript) {
          klaviyoScript.remove();
        }
      } else if (user === null) {
        queryClient.setQueryData(['authUser'], null);
        Cookie.remove('auth');
        Cookie.remove('uid');
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const refreshUserDoc = async (uid: string) => {
    await queryClient.invalidateQueries({
      queryKey: ['authUser', uid],
    });
  };

  const handleAuthError = (e: unknown) => {
    if (e instanceof FirebaseError) {
      return e;
    }
    logError(e);
    throw new Error('Encountered unknown error in auth flow');
  };

  const loginSocial: FirebaseAuthFn<AuthProvider> = async (args) => {
    try {
      setIsLoggingIn(true);
      const provider = initAuthProvider(args);
      if (isMobile()) {
        await setPersistence(AUTH, browserLocalPersistence);
      }
      const result = await signInWithPopup(AUTH, provider);
      setUser(result.user);
      setIsLoggingIn(false);
      return result;
    } catch (e) {
      setIsLoggingIn(false);
      return handleAuthError(e);
    }
  };

  const loginEmailPassword: FirebaseAuthFn<Credentials> = async (args) => {
    try {
      setIsLoggingIn(true);
      if (isMobile()) {
        await setPersistence(AUTH, browserLocalPersistence);
      }
      const userCredential = await signInWithEmailAndPassword(
        AUTH,
        args.email,
        args.password
      );
      setUser(userCredential.user);
      setIsLoggingIn(false);
      return userCredential;
    } catch (e) {
      setIsLoggingIn(false);
      return handleAuthError(e);
    }
  };

  // admin feature
  const signInWithToken: FirebaseAuthFn<Credentials> = async (args) => {
    try {
      setIsLoggingIn(true);
      const userCredential = await signInWithCustomToken(AUTH, args.email);
      setUser(userCredential.user);
      setIsLoggingIn(false);
      return userCredential;
    } catch (e) {
      setIsLoggingIn(false);
      return handleAuthError(e);
    }
  };

  const createAccountEmailPassword: FirebaseAuthFn<Credentials> = async (
    args
  ) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        AUTH,
        args.email,
        args.password
      );
      return userCredential;
    } catch (e) {
      return handleAuthError(e);
    }
  };

  const logout = async () => {
    const impersonated = Cookies.get('impersonated');
    if (!impersonated) {
      await setOffline(user?.uid);
    }
    await signOut(AUTH);
    setUser(null);
    queryClient.setQueryData(['authUser'], null);
    Cookie.remove('auth');
    Cookie.remove('algoliaUserToken');
    Cookie.remove('impersonated');
  };

  const sendPasswordResetEmail = async (email: string) => {
    const actionCodeSettings: ActionCodeSettings = {
      url: `${getHostUrl()}/reset-password`,
    };
    await _sendPasswordResetEmail(AUTH, email, actionCodeSettings);
  };

  const resetPassword = async (oobCode: string, password: string) => {
    await confirmPasswordReset(AUTH, oobCode, password);
  };

  const updateUserPassword = async (
    oldPassword: string,
    newPassword: string
  ) => {
    if (AUTH.currentUser?.email && isAccountEmailPassword()) {
      const credential = EmailAuthProvider.credential(
        AUTH.currentUser.email,
        oldPassword
      );
      try {
        await reauthenticateWithCredential(AUTH.currentUser, credential);
        await updatePassword(AUTH.currentUser, newPassword);
      } catch (err) {
        throw new Error('Incorrect password');
      }
    }
  };

  const isAccountEmailPassword = () => {
    if (AUTH.currentUser) {
      return AUTH.currentUser.providerData.some(
        (p) => p.providerId === 'password'
      );
    }
    return false;
  };

  const fetchProvidersForEmail = async (email: string) => {
    try {
      const methods = await fetchSignInMethodsForEmail(AUTH, email);
      if (methods?.length) {
        const formatted = methods.map((m) =>
          m === 'password'
            ? 'your email and password'
            : m.charAt(0).toUpperCase() + m.substring(1).replace('.com', '')
        );
        return formatted;
      }
    } catch (_e) {
      // invalid email
    }
    return [];
  };

  const updateUserEmail = (email: string) => {
    if (AUTH.currentUser) {
      updateEmail(AUTH.currentUser, email);
    }
  };

  const observedProducts = useRef<string[]>([]);
  const observerTimeout = useRef<any | null>(null);
  const observeProduct = (productId: string) => {
    if (!observedProducts.current.includes(productId)) {
      observedProducts.current.push(productId);
    }
    if (observerTimeout.current) {
      clearTimeout(observerTimeout.current);
    }
    observerTimeout.current = setTimeout(() => {
      if (userDoc?.uid) {
        addToInteractions(
          userDoc.uid,
          'my_feed_viewed_real',
          observedProducts.current
        );
        observedProducts.current = [];
      }
    }, 1000);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        userDoc,
        isLoadingUserDoc,
        isLoggingIn,
        logout,
        loginEmailPassword,
        loginSocial,
        signInWithToken,
        createAccountEmailPassword,
        sendEmailVerification,
        sendPasswordResetEmail,
        resetPassword,
        updateUserPassword,
        isAccountEmailPassword,
        verifyEmail,
        fetchProvidersForEmail,
        updateUserEmail,
        refreshUserDoc,
        sendPhoneVerification,
        signInWithPhone,
        linkPhone,
        showWelcomeBackModal,
        setShowWelcomeBackModal,
        observeProduct,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}

export default AuthProvider;
